<template>
  <v-container min-height="100" class="px-3 form-page-main" v-if="!pageLoading">
    <v-stepper v-model="curr" color="green" class="pb-6 pt-6 pl-4">
      <v-stepper-content
        class="pb-6 pt-4 pl-0"
        v-for="(step, n) in steps"
        :step="n + 1"
        :key="n"
      >
        <validation-observer :ref="'observer'">
          <v-form
            :ref="'stepForm'"
            v-model="step.valid"
            lazy-validation
            autocomplete="off"
          >
            <!-- Step 1 -->
            <h6 class="text-center mb-7 font-14px font-weight-medium text-333">
              {{ shopName }}
            </h6>
            <p class="primary--text font-weight-bold mb-7">
              店舗ユーザー新規登録
            </p>
            <v-card-text
              class="pb-0"
              v-for="field in step.fields"
              :key="field.name"
            >
              <v-row v-if="field.type == 'text' || field.type == 'password'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label v-if="field.label"
                      >{{ field.label }}
                      <span
                        v-if="field.rules.includes('required')"
                        class="required-text"
                        >必須</span
                      >
                    </v-label>
                    <v-text-field
                      :type="field.type"
                      :placeholder="field.placeholder"
                      solo
                      :id="field.name"
                      background-color="#EAEDF0"
                      v-model="field.value"
                      :error-messages="errors"
                      autocomplete="chrome-off"
                    >
                    </v-text-field>
                    <span class="post-label" v-if="field.post_label">{{
                      field.post_label
                    }}</span>
                    <div class="v-messages theme--light error--text">
                      {{ errors ? errors[0] : "" }}
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="field.type == 'dropdown' && !field.hidden_row">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="py-0 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label v-if="field.label"
                      >{{ field.label }}
                      <span
                        v-if="field.rules.includes('required')"
                        class="required-text"
                        >必須</span
                      >
                    </v-label>
                    <v-select
                      :label="field.placeholder"
                      solo
                      :items="field.listValues"
                      v-model="field.value"
                      :error-messages="errors"
                      :item-text="field.item_text"
                      :item-value="field.item_value"
                      background-color="#EAEDF0"
                    ></v-select>
                    <div class="v-messages theme--light error--text">
                      {{ errors ? errors[0] : "" }}
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="n == 1" class="pb-0">
              <v-row>
                <v-col cols="12" md="12" class="py-0 px-0">
                  <template v-if="user.role == 'P'">
                    <h6 class="font-16px text-666 fw-400">アクセス権限</h6>
                    <h6 class="font-16px text-666 fw-400">パートユーザー</h6>
                    <p class="font-12px text-85 fw-400">
                      パートユーザーは入力専用のユーザーです。
                    </p>
                  </template>
                  <template v-else>
                    <p class="font-12px text-85 mb-4 fw-400">
                      すべての情報を閲覧・編集できるマスターユーザーか日報、現金出納、買掛の登録や編集、他一部機能が利用可能なパートユーザーかを選択してください。
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </validation-observer>
        <v-btn
          block
          v-if="n + 1 <= lastStep"
          color="primary"
          class="stepper-btns mt-2"
          @click="moveToNextStep(n)"
          :loading="loading"
          :disabled="!step.valid"
          >次へ進む</v-btn
        >
        <v-btn
          v-else
          class="stepper-btns mt-2"
          block
          color="primary"
          @click="register(n, 'true')"
          :loading="loading"
          :disabled="loading || !step.valid"
          >登録完了</v-btn
        >
        <v-btn
          block
          class="mt-3"
          color="stepper-btns grey white--text"
          v-if="curr != 1"
          @click="curr = n"
          >戻る</v-btn
        >
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CreateUser",
  data() {
    return {
      shopName: "",
      loading: false,
      pageLoading: false,
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 1,
      steps: [
        {
          name: "Step 1",
          valid: true,
          fields: [
            {
              label: "ユーザー名",
              name: "surname",
              type: "text",
              placeholder: "姓",
              value: "",
              rules: "required:姓"
            },
            {
              label: "",
              name: "name",
              type: "text",
              placeholder: "名",
              value: "",
              rules: "required:名"
            },
            {
              label: "フリガナ",
              name: "furigana_surname",
              type: "text",
              placeholder: "セイ",
              value: "",
              rules: "required:セイ"
            },
            {
              label: "",
              name: "furigana_name",
              type: "text",
              placeholder: "メイ",
              value: "",
              rules: "required:メイ"
            },
            {
              label: "メールアドレス (ID)",
              name: "email",
              type: "text",
              placeholder: "mail@crosspoint.com",
              value: "",
              rules: "email|required:メールアドレス|only_english_lang_allowed"
            },
            {
              label: "パスワード",
              name: "password",
              type: "password",
              placeholder: "8文字以上の英数字を設定してください",
              value: "",
              rules:
                "required:パスワード|max:50|verify_password|only_english_lang_allowed",
              post_label: "※ 英大文字、英小文字、数字をすべて使用してください"
            },
            {
              label: "パスワード(確認用)",
              name: "password_confirmation",
              type: "password",
              placeholder: "もう一度入力してください",
              value: "",
              rules:
                "required:パスワード(確認用)|max:50|password_confirmed:password"
            }
          ]
        },
        {
          name: "Step 2",
          valid: true,
          fields: [
            {
              label: "役職",
              name: "position",
              type: "text",
              placeholder: "エリアマネージャー",
              value: "",
              rules: "required:役職",
              erorr_label: "役職"
            },
            {
              label: "電話番号",
              name: "telephone_number",
              type: "text",
              placeholder: "033-5413-1800",
              value: "",
              rules: "required:電話番号|enter_half_width_numbers_hyphens"
            },
            {
              label: "アクセス権限",
              name: "user_role",
              type: "dropdown",
              hidden_row: this.user?.role == "P" ? true : false,
              listValues: [
                { id: "M", text: "マスターユーザー" },
                { id: "P", text: "パートユーザー" }
              ],
              item_text: "text",
              item_value: "id",
              placeholder: "マスターユーザー",
              value: "",
              rules: "required:アクセス権限"
            },
            {
              label: "",
              name: "shop_id",
              type: "hidden",
              value: this.shop
            }
          ]
        }
      ],
      valid: false,
      stepForm: [],
      observer: []
    };
  },
  props: ["shop"],
  computed: {
    ...mapGetters(["user"])
  },
  created() {
    this.getDataFromApi();
    document.addEventListener("DOMContentLoaded", function() {
      AutoKana.bind("#name", "#furigana_name", { katakana: true });
      AutoKana.bind("#surname", "#furigana_surname", { katakana: true });
    });
  },
  methods: {
    async getDataFromApi() {
      this.pageLoading = true;
      this.loading = true;
      await this.$store
        .dispatch("SHOP_GET", { id: this.shop })
        .then(response => {
          let shop = response.data.data.shop;
          this.shopName = shop.store_display_name
            ? shop.store_display_name
            : shop.store_name;

          let authUserData = {
            shop_id: this.shop, //Shop Id
            user_id: this.$store.getters.user.id
          };

          this.$store.dispatch("SHOP_USER_AUTH", authUserData).finally(() => {
            if (this.$store.getters.getShopAuthUser[0].user_role != "M") {
              this.userRole = "P";
              this.steps.forEach(step => {
                step.fields.forEach(field => {
                  if (field) {
                    if (field.name == "user_role") {
                      field.listValues = [{ id: "P", text: "パートユーザー" }];
                      field.placeholder = "パートユーザー";
                      field.value = "P";
                      field.rules = "";
                    }
                  }
                });
              });
            }
            this.loading = false;
            this.pageLoading = false;
          });
        });
    },

    stepComplete(step) {
      return this.curr > step;
    },

    stepStatus(step) {
      return this.curr > parseInt(step) ? "green" : "blue";
    },

    moveToNextStep(n) {
      this.steps[n].valid = false;
      this.$refs.observer[n].validate().then(success => {
        if (!success) {
          return;
        }
        this.steps[n].valid = true;
        // continue to next
        this.curr = n + 2;
      });
    },

    register(currentStep = "false", clicked = "false") {
      this.$refs.observer[currentStep].validate().then(success => {
        if (!success) {
          return;
        }

        this.loading = true;
        let data = new FormData();
        data.shop_id = this.shop;
        let isFilledFeilds = true;
        this.steps.forEach(step => {
          step.fields.forEach(field => {
            if (field) {
              if ({}.hasOwnProperty.call(field, "additional_field")) {
                data.append(
                  field.additional_field.name,
                  field.additional_field.value
                );
              }
              data.append(field.name, field.value);
              if (field.value == "") {
                isFilledFeilds = false;
              }
            }
          });
        });
        if (currentStep != this.lastStep && isFilledFeilds) {
          this.moveToNextStep(currentStep);
          this.loading = false;
          return;
        }

        this.$store
          .dispatch("SHOP_USER_REGISTER", data)
          .then(response => {
            if (response.status === 200) {
              this.$router.push({
                name: "StoreDetails",
                params: { id: this.shop }
              });
              this.$store.commit("showAlert", {
                text:
                  "ユーザーを追加しました。 追加メンバーにメールを送信しました。",
                successStatus: "info"
              });
              this.loading = false;
            }
          })
          .catch(error => {
            if (currentStep != "false") {
              if (error?.data?.errors)
                this.$refs.observer[currentStep].setErrors(error?.data?.errors);
              if (clicked == "true" && error.status == 422) {
                if (error?.data?.errors?.email) return;
                this.moveToNextStep(currentStep);
              }
            } else {
              this.curr = 1;
              this.loading = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
